<template>
  <div
    class="video-promo-1 d-flex justify-content-center align-items-center overflow-hidden"
    :class="{'video-promo-1_mobile': isMobile}"
  >
    <transition name="fade-block">
      <div
        v-if="!playStatus"
        ref="playBtn"
        class="video-icon play-btn"
      >
        <div class="btn-circle play-animation">
        </div>
        <div
          class="btn-circle play-animation animation-short"
        >
        </div>
        <!-- Play Icon -->
        <div class="play-icon">
          <svg
            data-v-568ed5e5=""
            class="svg-inline--fa fa-play fa-w-14"
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="play"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            data-fa-i2svg=""
          >
            <path
              fill="currentColor"
              d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"
            >

            </path>
          </svg>
        </div>
      </div>
    </transition>
    <transition name="fade-block">
      <div
        v-if="!playStatus"
        class="video-cover"
      >
        <!--        <img-->
        <!--          :src="coverLink"-->
        <!--          :alt="`Video Cover ${videoId}`"-->
        <!--        >-->
        <img
          v-if="!showBlock"
          :src="require('../assets/images/loading.svg')"
          :alt="`Video Cover ${videoId}`"
        >
        <v-lazy-image
          v-else
          :src="coverLink"
          :srcPlaceholder="require('../assets/images/loading.svg')"
          :alt="`Video Cover ${videoId}`"
        />
      </div>
    </transition>
    <div
      ref="vimeoPlayer"
      :data-vimeo-url="vimeoUrl"
      class="vimeo-player"
    >
    </div>
  </div>
</template>

<script>
import Player from '@vimeo/player';

export default {
  name: 'VimeoPlayer',
  props: {
    showBlock: {
      type: Boolean,
    },
    vimeoUrl: {
      type: String,
    },
    playVideoId: {
      type: Number,
      default: -1,
    },
    videoId: {
      type: Number,
      default: 1,
    },
    coverUrl: {
      type: String,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    vLazyImage: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      player: {},
      playStatus: false,
      replay: false,
    };
  },
  computed: {
    coverLink() {
      try {
        return require(`../home-assets/img/feature-thumb/${this.coverUrl}`);
      } catch (e) {
        return false;
      }
    },
  },
  watch: {
    playVideoId(newVal) {
      if (newVal !== this.videoId && this.playStatus) {
        this.stoppedVideo();
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      const player = this.$refs.vimeoPlayer;
      this.player = new Player(player, {
        id: this.vimeoUrl,
        // responsive: !this.isMobile,
        playsinline: !this.isMobile,
        title: false,
      });
      this.player.on('play', () => {
        this.playVideo();
      });
      this.player.on('bufferstart', () => {
        this.playVideo();
      });
      this.player.on('pause', () => {
        this.stopVideo();
      });
      this.player.on('ended', () => {
        this.player.unload()
          .then(() => {
            this.replay = true;
            this.player.play()
              .then(() => {
                this.stoppedVideo();
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      });
    });
  },
  methods: {
    playVideo() {
      console.log('playVideo', this.replay);
      if (!this.replay) {
        this.playStatus = true;
        this.$emit('update:playVideoId', this.videoId);
      }
    },
    stopVideo() {
      this.playStatus = false;
      this.replay = false;
    },
    stoppedVideo() {
      this.player.pause()
        .then()
        .catch((err) => {
          console.error('err', err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
 .video-promo-1 {
   max-width: 1128px;
   margin-left: auto;
   margin-right: auto;
   border-radius: 8px;

   &_mobile {
     border-radius: 0;
   }

 }

.video-promo {
    width: 100%;
    height: 100%;
  }

  .vimeo-player {
    width: 100%;
    height: 100%;
  }

  .play-btn {
    cursor: pointer;
    z-index: 50;
    pointer-events: none;
  }

  .video-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 49;
    pointer-events: none;
    overflow: hidden;

    img {
      min-width: 100%;
    }

    @media (max-width: 767px) {
      img {
        width: 100%;
        height: 100%;
        max-width: none;
        object-fit: cover;
      }

    }

  }

 .video-icon {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   margin: 0;

   svg {
     width: .875em;
   }

 }

 .play-btn {
   display: inline-block;
   height:90px;
   width:90px;
   border:10px solid #FFFFFF;
   opacity: 0.8;
   border-radius: 50%;
 }

 .btn-circle {
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   height:100%;
   width:100%;
   border-radius: 50%;
   border:3px solid #FFFFFF;
 }

 .play-icon {
   font-size: 22px;
   color: #FFFFFF;
   position: absolute;
   top: 50%;
   left: 53%;
   transform: translate(-50%, -50%);
 }

 @keyframes grow {
   0% {
     transform: scale(1);
     opacity: 0;
   }

   20% {
     transform: scale(1.4);
     opacity: 0.2;
   }

   100% {
     transform: scale(3);
     opacity: 0;
   }
 }

 .play-animation {
   animation: grow 1s infinite;
 }

</style>

<style lang="scss">

.video-promo-1 {
  iframe {
    width: 100% !important;
    height: 100% !important;
  }
}

</style>

<!-- Created by zas on 07.04.2021  -->
